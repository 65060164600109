import React from "react";
import { navigate } from "gatsby";

function Heading({ title, heading, des, heroTheme }) {
  return (
    <div className="upper_class">
      <div className="contact1">
        <div className="containerwrap">
          <h4 className="contact1Title ">{title}</h4>
          <h1 className="contact1Heading roboto">{heading}</h1>
          <div className="contact1Desc open-sans">{des}</div>
        </div>
      </div>
    </div>
  );
}

export default Heading;
