import * as React from "react";
import "../styles/contact.scss";
import Layout from "../components/layoutnew";
import Heading from "../components/contact/Heading";
import ContactForm from "../components/contact/contactForm";
// import NeedHelp from "../components/contact/needhelp";
import { graphql } from "gatsby";
import { useMemo } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import SEO from "../components/seo";
import ContactFormNew from "../components/contact/contactFormNew";

const Contact = ({ data }) => {
  const getContactData = (data) => {
    const values = data.allStrapiContact.edges[0].node;
    return values;
  };
  const getData = useMemo(() => getContactData(data), [data]);
  const indexData = data.allStrapiIndexpage.edges[0].node;
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcbmXUdAAAAAM8BACZCq5rzXPHfn1BpVeNv5f5o">
      <Layout slug={getData.slug} locale={getData.locale}>
        <SEO
          description={getData.metaDes}
          slug={getData.slug}
          locale={getData.locale}
          title={getData.metaTitle}
        />
        <Heading
          title={getData.title}
          heading={getData.heading}
          des={getData.des}
          heroTheme={getData.heroTheme.url}
        />
        {getData.locale === "en-IN" ? (
          <ContactForm
            data={getData}
            locale={getData.locale}
            testimonials={indexData.testimonials}
          />
        ) : (
          <ContactFormNew
            data={getData}
            locale={getData.locale}
            testimonials={indexData.testimonials}
          />
        )}
      </Layout>
    </GoogleReCaptchaProvider>
  );
};

export default Contact;

export const query = graphql`
  query Contact($locale: String!) {
    allStrapiIndexpage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          testimonials {
            name
            content {
              data {
                content
              }
            }
            imageUrl
          }
        }
      }
    }
    allStrapiContact(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          slug
          locale
          metaDes
          metaTitle
          btn
          contactDetail {
            contact
            sales
            support
            humanResource
            linkedIn
            emailId
            addressIn
            addressSe
            phoneNumberIn
            phoneNumberSe
          }
          contactLayout {
            email
            india
            sales
            sweden
            contact
            support
            information
            phoneNumber
            socialMedia
            humanResources
            officeLocation
            indiaPhoneNumber
            alertText
            alertTitle
            contactServices {
              src
              title
            }
            supportCards {
              btn
              src
              title
              description
            }
          }
          des
          file
          checkboxText1
          checkboxText2
          heading
          email
          infotitle1
          infotitle2
          message
          firstName
          lastName
          heroTheme {
            url
          }
          needhelp {
            strapi_json_value {
              answer
              question
            }
          }
          needhelpheading
          needhelptitle
          orgname
          phonenumber
          title
        }
      }
    }
  }
`;
